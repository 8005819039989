<template>
  <div>

    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("navbar.salesexports") }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="filterForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="2">
                  <v-select
                      v-model="filterCriteria.salesType"
                      label="Tipo de Venta"
                      item-text="label"
                      item-value="value"
                      filled
                      :items="saleTypeOpts"
                      multiple
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                      v-model="filterCriteria.operatorId"
                      v-bind:label="$t('navbar.Operators')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_operators"
                      :loading="isSearching['operator']"
                      :items="operatorsOpts"
                      hide-no-data
                      filled
                      multiple
                      :rules="requiredRules"
                      clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                      v-model="filterCriteria.brandId"
                      v-bind:label="$t('navbar.Brands')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_brands"
                      :loading="isSearching['brand']"
                      :items="brandOpts"
                      hide-no-data
                      filled
                      :rules="requiredRules"
                      multiple
                      clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterCriteria.salesDate"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterCriteria.salesDate"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12" md="2">

                  <div class="d-flex justify-content-end">

                    <div class="d-flex flex-column">
                      <div class="sales-totals-header">
                        <span class="font-weight-bold">Total</span>
                      </div>
                      <div class="sales-totals-container mr-1">
                        <span>{{currency_format(total)}}</span>
                      </div>
                    </div>

                    <div class="d-flex flex-column">
                      <div class="sales-totals-header">
                        <span class="font-weight-bold">IVA</span>
                      </div>
                      <div class="sales-totals-container">
                        <span>{{currency_format(totalTax)}}</span>
                      </div>
                    </div>

                  </div>

                </v-col>

              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="blue darken-1 mr-2" @click="onClickFilter">
              {{ $t("go") }}
            </v-btn>
            <v-btn :disabled="disableExports" color="warning mr-2" dark @click="downloadExport('afe')">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Tipo Covalco
            </v-btn>
            <v-btn :disabled="disableExports" color="warning" dark @click="downloadExport('frit')">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Tipo Frit
            </v-btn>

          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
                ref="tableRefSalesExport"
                id="datatable_sales_export"
                fixed-header
                :columns="columns"
                :table-data="data"
                :border-around="true"
                :border-x="true"
                :border-y="true"
            />
            <div v-show="!data.length" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                  :total="totaldata"
                  :page-index="pageIndex"
                  :page-size="pageSize"
                  :page-size-option="pageOptions"
                  @on-page-number-change="pageNumberChange"
                  @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>

        </v-row>


      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import download from "js-file-download";
import {logError} from "../../../utils";


export default {
  name: "SalesExports",
  data: function() {
    return {
      filter_main_credentials_fetch_url: "reporting/columna/getfiltermaincredentials",
      isSearching: {},
      operatorsOpts:[],
      brandOpts:[],

      saleTypeOpts: [
        {label: 'Venta en firme', value: 'FIRM_ORDER'},
        {label: 'Venta sugerida', value: 'SUGGESTED_ORDER'},
        {label: 'Sin Venta', value: 'NO_SALE'},
      ],
      search_operators: null,
      search_brands: null,

      filtered_operators: [],
      select_startDate_menu: false,
      filterCriteria: {
        salesType: ['FIRM_ORDER'],
        operatorId: null,
        brandId: null,
        salesDate: moment().format('YYYY-MM-DD'),

      },
      totalTax: 0,
      total: 0,
      disableExports: false,
      sorting: null,
      valid: true,
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      totaldata: 0,
      data: [],
      requiredRules: [v => !!v || "Required"],


      columns: [
        {
          key: "salesDate",
          field: "salesDate",
          title: "Fecha",
        },
        {
          key: "operator",
          field: "operator",
          title: "Operador",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>
              {this.deepGet(row, 'operator.name')}
            </span>;
          }
        },
        {
          key: "brand",
          field: "brand",
          title: "Brand",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>
              {this.deepGet(row, 'worksession_pos_brand.brand.name')}
            </span>;
          }
        },
        {
          key: "pos",
          field: "pos",
          title: "Pos",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>
              {this.deepGet(row, 'worksession_pos_brand.worksession_po.po.name')}
            </span>;
          }
        },
        {
          key: "gpv",
          field: "gpv",
          title: "GPV",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>
              {this.deepGet(row, 'worksession_pos_brand.worksession_po.worksession.user.name')}&nbsp;
              {this.deepGet(row, 'worksession_pos_brand.worksession_po.worksession.user.surname')}
            </span>;
          },
        },
        {
          key: "total",
          field: "total",
          title: "Total (sin Iva)",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{this.currency_format(row.total)}</span>;
          },
        },
        {
          key: "vat",
          field: "vat",
          title: "Iva",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{this.currency_format(row.tax)}</span>;
          },
        },

      ],
    };
  },
  watch: {

    search_operators(val) {
      const regex = new RegExp(val, 'gi');
      return this.operatorsOpts.filter(el=>el.name.match(regex))
    },

    search_brands(val) {
      const regex = new RegExp(val, 'gi');
      return this.brandOpts.filter(el=>el.name.match(regex))
    },

  },

  computed: {

  },

  methods: {

    async getOpts(fieldname) {
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=`;
      let form_data = { ...this.filterCriteria };
      this.isSearching[fieldname] = true;
      const { data } = await ApiService.post(fetch_url, form_data);
      this.isSearching[fieldname] = false;
      return data
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    async downloadExport(type) {
      this.disableExports = true
      this.show();
      try {
        let fetch_url = this.extendUrlWithQueryParams(`reporting/download/sales-export-${type}`);
        const response = await ApiService.get(fetch_url, {
          responseType: "blob",
        });
        const fileName = `${type}_export_${Date.now()}.zip`
        download(response, fileName);
      }catch (err) {
        console.error(err)
        logError("Error al descargar export")
      }
      this.disableExports = false
      this.close();
    },

    async onClickFilter() {
      if (this.$refs.filterForm.validate()) {
        await this.getDataFromApi();
      }
    },

    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_sales_export"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },


    extendUrlWithQueryParams(url) {
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      const q = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:null
      });
      return (q && q!=="") ? `${url}?${q}` : url
    },



    async getDataFromApi() {
      this.show();
      let fetch_url = this.extendUrlWithQueryParams("reporting/sales-export");

      let form_data = { ...this.filterCriteria };
      try {

        const { data, aggregate, total } = await ApiService.post(fetch_url, form_data);
        this.total = aggregate.total
        this.totalTax = aggregate.tax
        this.data = data;
        this.totaldata = total;
        this.close();
      } catch (error) {
        this.close();
      }
    },


    async init() {
      this.operatorsOpts = await this.getOpts("operators");
      this.brandOpts = await this.getOpts("brands");

    }
  },
  async mounted() {
    await this.init();
  }
};
</script>

<style>

.sales-totals-container{
  padding: 10px 5px 0 5px;
  width: auto;
  border: 1px solid #999;
  border-radius: 3px;
}
.sales-totals-container span {
  font-size: 30px;
}

</style>
